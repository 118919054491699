// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-development-jsx": () => import("./../../../src/pages/development.jsx" /* webpackChunkName: "component---src-pages-development-jsx" */),
  "component---src-pages-film-tv-jsx": () => import("./../../../src/pages/film-tv.jsx" /* webpackChunkName: "component---src-pages-film-tv-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-music-jsx": () => import("./../../../src/pages/music.jsx" /* webpackChunkName: "component---src-pages-music-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */)
}

